import * as React from "react"

import Layout from "../components/layout"
import Inhaltsverzeichnis from "../components/inhaltsverzeichnis.jsx"

import Seo from "../components/seo"
import Button from "../components/button-link"
import AmazonItem from "../components/amazon-item"

import rare from '../images/hotwheels-rare-logo.png'
import carculture from '../images/car-culture.jpg'
import bluecard from '../images/blue-card.webp'
import Video from "../components/video"

const Trucks = () => {
  if (typeof window !== "undefined") {
    if (window.gtag) {
      window.gtag('event', 'view', {
        'event_category': 'page view',
        'event_label': 'trucks'
      });
    }
    
  }
  return (
  <Layout>
    <Seo title="Hot Wheels Trucks" description={"Die besten Monster Trucks und Spielsets von Hot Wheels."}> 
      <script type="application/ld+json">
        {`{
          "@type": "WebSite",
          "@id": "https://www.hotwheels.autos/",
          "url": "https://www.hotwheels.autos",
          "name": "HotWheels.autos",
          "inLanguage": "de-DE"
        },
        {
          "@type": "WebPage",
          "@id": "https://www.hotwheels.autos/trucks/",
          "url": "https://www.hotwheels.autos/trucks/",
          "name": "Die besten Hot Wheels Trucks in 2023",
          "datePublished": "2022-04-04",
          "dateModified": "2022-04-04",
          "isPartOf": {
              "@id": "https://www.hotwheels.autos"
          },
          "inLanguage": "de-DE"
        },
        {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "numberOfItems": "7",
          "itemListElement": [
            "Hot Wheels Bone Shaker Monster Truck",
            "Hot Wheels Mega-Wrex Monster Truck",
            "Hot Wheels Podium Crasher",
            "Hot Wheels Monster Trucks Säbelzahntiger Angriff",
            "Hot Wheels Monster Trucks Sumpfattacke",
            "Hot Wheels Monster Trucks Looping Challenge-Spielset",
            "Hot Wheels Monster Trucks Wettkampf-Rennstrecke"
          ],
          "itemListOrder": "https://schema.org/ItemListOrderDescending",
          "name": "Die Besten Hot Wheels Monster Trucks"
        }`}
      </script>
    </Seo>
    <div className='content'>
      <div className='row'>
        <div className=''>
          <h1 style={{marginBottom: 15}}>Hot Wheels Monster Trucks: Die besten Sets und Stunts im Jahr 2023</h1>
          <p>Hot Wheels dreht sich nicht nur um schnelle Autos und elegante Strecken, sondern auch um monströse Fahrzeuge, die alles in ihrem Weg zermalmen können. Hot Wheels Monster Trucks sind der perfekte Weg, um der Sammlung Ihres Kindes etwas Großes hinzuzufügen, und in diesem Artikel zeigen wir Ihnen die besten Sets und Stunts, um das Beste aus Ihren monströsen Fahrzeugen zu machen.</p>
          <h2 id="trucks" className="border" style={{marginTop: 75}}>Hot Wheels Monster Trucks</h2>
          <p>Hier sind einige der besten Hot Wheels Monster Trucks:</p>

          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/71W2LWTue3L._AC_SX569_.jpg"
            altText="Bone Shaker Monster Truck"
            url="https://amzn.to/3ZSaCDZ" 
            title="Bone Shaker Monster Truck"
            text="Mit einem bedrohlichen Schädel auf der Motorhaube und übergroßen Rädern ist der Bone Shaker Monster Truck einer der beliebtesten in der Hot Wheels Monster Trucks-Reihe."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/81EoH8xMxCL._AC_SX569_.jpg" 
            altText="Mega-Wrex Monster Truck"
            url="https://amzn.to/3Lq77jw" 
            title="Mega-Wrex Monster Truck"
            text='Mit massiven grünen Stacheln und einem furchterregenden Aussehen ist der Mega-Wrex Monster Truck ein Favorit unter Kindern, die Dinosaurier und andere prähistorische Kreaturen lieben.'
          />
        <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/W/IMAGERENDERING_521856-T2/images/I/61nkRIRnUWL._AC_SX679_.jpg" 
            altText="Hot Wheels Podium Crasher"
            url="https://amzn.to/3Jj2uFy" 
            title="Hot Wheels Podium Crasher"
            text="Wie die anderen Hot Wheels Monster Trucks ist auch der Podium Crasher mit seinen riesigen Rädern und seinem robusten Rahmen bereit, Hindernisse zu überwinden und spektakuläre Stunts auszuführen. Wenn dein Kind ein Fan von Monster Trucks und aufregender Action ist, wird der Hot Wheels Podium Crasher sicherlich der richtige Truck sein."
          />
         
        <h2 id="stunts" className="border" style={{marginTop: 75}}>Monster Trucks Stunts</h2>
        <p>Hot Wheels Monster Trucks-Stunts sind alles über große, kühne Aktionen, die das Herz Ihres Kindes höher schlagen lassen werden. Hier sind einige der besten Hot Wheels Monster Trucks-Stunts:</p>
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71AuVoA6dVS._AC_SX522_.jpg"
            altText="Monster Trucks Säbelzahntiger Angriff" 
            url="https://amzn.to/3TrtreH" 
            title="Monster Trucks Säbelzahntiger Angriff "
            text="Monster Trucks Säbelzahntiger Angriff ist ein spannendes Spielset für Kinder, das ihnen ermöglicht, ihre eigenen epischen Monster-Truck-Abenteuer zu erleben. Mit einem riesigen Säbelzahntiger, der als Rampe für spektakuläre Stunts dient, und zwei einzigartigen Monster-Trucks, die bereit sind, sich dem Angriff zu stellen, ist dieses Set perfekt für Kinder, die auf der Suche nach actionreicher Unterhaltung sind. Die Säbelzahntiger-Rampe leuchtet auch im Dunkeln, um noch mehr Spaß zu bieten. Lassen Sie Ihre Kinder ihre Kreativität und Fantasie mit dem Monster Trucks Säbelzahntiger Angriff Spielset ausleben."
          />
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71Tpuo6eNBL._AC_SX679_.jpg"
            altText="Hot Wheels Monster Trucks Sumpfattacke" 
            url="https://amzn.to/42eTCcB" 
            title="Hot Wheels Monster Trucks Sumpfattacke"
            text='Hot Wheels Monster Trucks Sumpfattacke ist ein aufregendes Spielset, das deinem Kind stundenlangen Spielspaß bietet. Das Set enthält zwei Monster Trucks, die speziell für den Einsatz im Sumpfgebiet entwickelt wurden. Mit vielen Hindernissen und einem großartigen Sumpf-Design, wird dieses Spielset die Fantasie deines Kindes anregen und ihm ermöglichen, unglaubliche Abenteuer zu erleben. Das Spielset ist robust gebaut und verfügt über realistische Soundeffekte und Bewegungen, die das Spiel noch realistischer machen.'
          />
        <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/61UnTAVQ38L._AC_SX569_.jpg" 
            altText="Monster Trucks Looping Challenge-Spielset"
            url="https://amzn.to/3Jilmo1" 
            title="onster Trucks Looping Challenge-Spielset (Leuchteffekt im Dunkeln)"
            text="Das Hot Wheels HBN02 - Monster Trucks Looping Challenge-Spielset ist das perfekte Spielset für alle Monster Truck-Fans da draußen! Mit einem coolen Leuchteffekt im Dunkeln sorgt es für zusätzlichen Spaß und Spannung. Das Set enthält eine riesige Schleife, die für spektakuläre Stunts genutzt werden kann, sowie zwei Monster Trucks, die mit Leichtigkeit durch die Schleife rasen können. Das HBN02-Set ist ein Muss für alle, die auf der Suche nach einem actiongeladenen Spielset sind, das für stundenlangen Spielspaß sorgt!"
          />
          
          <AmazonItem 
            imgUrl="https://m.media-amazon.com/images/I/71tRribsiLL._AC_SX679_.jpg" 
            altText="Monster Trucks Wettkampf-Rennstrecke"
            url="https://amzn.to/3TjBQkk"
            title="Monster Trucks Wettkampf-Rennstrecke"
            text="Das Hot Wheels HGV12 Monster Trucks Wettkampf-Rennstrecke Set ist perfekt für alle Kinder, die spannende Rennen mit ihren Hot Wheels Monster Trucks erleben wollen. Die Strecke ist so konzipiert, dass zwei Monster Trucks gleichzeitig gegeneinander antreten können, mit Herausforderungen wie Sprüngen, Loopings und vielem mehr. Die Wettkampf-Rennstrecke sorgt für stundenlangen Spaß und Aufregung und ist ein tolles Geschenk für alle Hot Wheels Fans."
          />
        </div>
        <div className='content-list-wrapper' style={{width: 240, minWidth: 240, marginLeft: 25}}>
            <Inhaltsverzeichnis content={[
                {
                    id: "trucks",
                    text: "Trucks"
                }, 
                {
                    id: "stunts",
                    text: "Stunts"
                }
            ]}/>
        </div>
       
       </div>
       
     </div>
    
   
  </Layout>
)}

/*

{
              "@type": "Product",
              "name": "Hot Wheels Bone Shaker Monster Truck",
              "image": "https://m.media-amazon.com/images/I/81SrErCcyuS._AC_SL1500_.jpg",
              "description": "Mit einem bedrohlichen Schädel auf der Motorhaube und übergroßen Rädern ist der Bone Shaker Monster Truck einer der beliebtesten in der Hot Wheels Monster Trucks-Reihe.",
              "brand": {
                "@type": "Brand",
                "name": "Hot Wheels"
              },
              "audience": {
                  "@type": "PeopleAudience",
                  "suggestedGender": "unisex",
                  "suggestedMinAge": 5,
                  "suggestedMaxAge": 14,
                  "age_group": "kids"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.6",
                "reviewCount": "6"
              },
              "keywords": "Hot Wheels, Monster Trucks",
              "offers": {
                "@type": "Offer",
                "url": "${""}",
                "availability": "https://schema.org/InStock",
                "price": "27",
                "priceCurrency": "EUR"
              }
            },

*/
export default Trucks
